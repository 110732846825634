<template>
    <div>
        <b-card v-if="!currentMarketplaceId">
            <h5>Looks like there is no marketplace selected. Please go back to inventory to pick marketplaces and select
                items to add to the shipment</h5>
        </b-card>
        <b-card v-else>
            <form-wizard
                color="#7367F0"
                :title="null"
                :subtitle="null"
                shape="square"
                finish-button-text="Create shipment"
                back-button-text="Previous"
                class="mb-3"
            >
                <template #finish>
                    <b-button variant="primary" :disabled="loading" @click="createShipmentPlan">
                        {{ loading ? 'Loading...' : 'Create shipment' }}
                    </b-button>
                </template>
                <tab-content
                    title="Confirm items"
                >
                    <b-row>
                        <b-col
                            cols="12"
                            class="mb-2"
                        >
                            <h5 class="mb-0">
                                Confirm items
                            </h5>
                            <small class="text-muted">
                                Please verify that these are the items you want to add to the shipment
                            </small>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="12"
                            class="mb-2"
                        >
                            <div v-if="loading" class="text-center">
                                <b-spinner
                                    label="Loading..."
                                    class="m-5"
                                />
                            </div>
                            <b-table v-else-if="items.length > 0" :items="items" :fields="itemsTableFields">
                                <template #cell(imageUrl)="data">
                                    <img :src="data.item.imageUrl" :alt="data.item.title" class="img-fluid" width="100">
                                </template>
                                <template #cell(quantity)="data">
                                    <b-form-input
                                        v-model="data.item.quantity"
                                        type="number"
                                        min="0"
                                        size="sm"
                                        @change="updateItem(data.item)"
                                    />
                                </template>
                                <template #cell(quantityInBox)="data">
                                    <b-form-input
                                        v-model="data.item.quantityInBox"
                                        type="number"
                                        min="1"
                                        size="sm"
                                        @change="updateItem(data.item)"
                                    />
                                </template>
                                <template #cell(condition)="data">
                                    <b-form-select
                                        v-model="data.item.condition"
                                        :options="AMAZON_ITEM_CONDITIONS"
                                        size="sm"
                                        @change="updateItem(data.item)"
                                    />
                                </template>
                                <template #cell(options)="data">
                                    <b-button
                                        variant="outline-danger"
                                        size="sm"
                                        @click="triggerRemoveItem(data.item)"
                                    >
                                        <b-icon-trash />
                                    </b-button>
                                </template>
                            </b-table>
                            <template v-else>
                                <h5>Looks like there are no items added to the shipment. Please go back to inventory to
                                    select
                                    items to add to the shipment</h5>
                            </template>
                        </b-col>
                    </b-row>
                </tab-content>

                <tab-content
                    title="Address"
                    :before-change="validateAddress"
                >
                    <validation-observer
                        ref="addressRules"
                        tag="form"
                    >
                        <b-row>
                            <b-col
                                cols="12"
                                class="mb-2"
                            >
                                <h5 class="mb-0">
                                    Address
                                </h5>
                                <small class="text-muted">
                                    The address from which the inbound shipment will be sent.
                                </small>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="mb-2">
                                <b-form-group
                                    label="Name"
                                    label-for="name"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="name"
                                        rules="required"
                                    >
                                        <b-form-input
                                            v-model="address.name"
                                            name="name"
                                            :state="errors.length > 0 ? false:null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="mb-2">
                                <b-form-group
                                    label="Address line 1"
                                    label-for="address-line-1"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Address line 1"
                                        rules="required"
                                    >
                                        <b-form-input
                                            v-model="address.addressLine1"
                                            name="address-line-1"
                                            :state="errors.length > 0 ? false:null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6" class="mb-2">
                                <b-form-group
                                    label="Address line 2"
                                    label-for="address-line-2"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Address line 2"
                                    >
                                        <b-form-input
                                            v-model="address.addressLine2"
                                            name="address-line-2"
                                            :state="errors.length > 0 ? false:null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="mb-2">
                                <b-form-group
                                    label="City"
                                    label-for="city"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="City"
                                        rules="required"
                                    >
                                        <b-form-input
                                            v-model="address.city"
                                            name="city"
                                            :state="errors.length > 0 ? false:null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6" class="mb-2">
                                <b-form-group
                                    label="State or Province"
                                    label-for="state"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="State or Province"
                                    >
                                        <b-form-input
                                            v-model="address.stateOrProvinceCode"
                                            name="stateOrProvinceCode"
                                            :state="errors.length > 0 ? false:null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="mb-2">
                                <b-form-group
                                    label="Country"
                                    label-for="country"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Country"
                                        rules="required"
                                    >
                                        <b-form-select
                                            v-model="address.countryCode"
                                            name="countryCode"
                                            :state="errors.length > 0 ? false : null"
                                        >
                                            <b-form-select-option
                                                v-for="(country, countryKey) in Countries"
                                                :key="countryKey"
                                                :value="country.iso"
                                            >
                                                {{ country.name }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6" class="mb-2">
                                <b-form-group
                                    label="Postal code"
                                    label-for="postal-code"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Postal code"
                                        rules="required"
                                    >
                                        <b-form-input
                                            v-model="address.postalCode"
                                            name="postalCode"
                                            :state="errors.length > 0 ? false:null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </validation-observer>
                </tab-content>
                <tab-content
                    title="Additional information"
                >
                    <validation-observer
                        ref="additionalInfoRules"
                        tag="form"
                    >
                        <b-row>
                            <b-col
                                cols="12"
                                class="mb-2"
                            >
                                <h5 class="mb-0">
                                    General information
                                </h5>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="mb-2">
                                <b-form-group
                                    label="Shipment name"
                                    label-for="shipment-name"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Shipment name"
                                        rules="required"
                                    >
                                        <b-form-input
                                            v-model="shipmentName"
                                            name="shipment-name"
                                            :state="errors.length > 0 ? false:null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col
                                cols="12"
                                class="mb-2"
                            >
                                <h5 class="mb-0">
                                    Label Prep Preference
                                </h5>
                                <small class="text-muted">
                                    The preference for label preparation for an inbound shipment.
                                </small>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" class="mb-2">
                                <b-form-group>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Label Prep Preference"
                                        rules="required"
                                    >
                                        <b-form-radio v-model="labelPrepPreference" name="some-radios"
                                                      value="SELLER_LABEL"
                                        >
                                            Seller label
                                        </b-form-radio>
                                        <p class="small mb-3">
                                            The seller labels the items in the inbound shipment when labels are
                                            required.
                                        </p>
                                        <b-form-radio v-model="labelPrepPreference" name="some-radios"
                                                      value="AMAZON_LABEL_ONLY"
                                        >
                                            Amazon label only
                                        </b-form-radio>
                                        <p class="small mb-3">
                                            Amazon attempts to label the items in the inbound shipment when labels are
                                            required. If Amazon determines that it does not have the information
                                            required to successfully label an item, that item is not included in the
                                            inbound shipment plan.
                                        </p>
                                        <b-form-radio v-model="labelPrepPreference" name="some-radios"
                                                      value="AMAZON_LABEL_PREFERRED"
                                        >
                                            Amazon label preferred
                                        </b-form-radio>
                                        <p class="small">
                                            Amazon attempts to label the items in the inbound shipment when labels are
                                            required. If Amazon determines that it does not have the information
                                            required to successfully label an item, that item is included in the inbound
                                            shipment plan and the seller must label it.
                                        </p>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </validation-observer>
                </tab-content>

            </form-wizard>
        </b-card>
        <modal-confirm-vuexy
            reference="removeItemModal"
            modal-id="removeItemModal"
            title="Remove item?"
            text="Are you sure you want to remove this item?"
            :ok-function="removeItem"
        />
    </div>
</template>

<script>
import Axios from "axios"

require('@core/scss/vue/libs/vue-wizard.scss')

import {
    BCard,
} from 'bootstrap-vue'
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
    BRow,
    BCol,
    BTable,
    BSpinner,
    BButton,
    BIconTrash,
    BFormInput,
    BFormGroup,
    BFormRadio,
    BFormSelect,
    BFormSelectOption,
} from 'bootstrap-vue'
import ModalConfirmVuexy from '@/components/Modals/ModalConfirmVuexy'
import {ValidationObserver, ValidationProvider} from "vee-validate"
import {required} from '@validations'
import {AMAZON_ITEM_CONDITIONS} from "@/services/AmazonItemConditions"
import {Countries} from "@/helpers/Countries"

export default {
    name: 'inventory.createAmazonShipment',
    components: {
        BCard,
        FormWizard,
        TabContent,
        BRow,
        BCol,
        BTable,
        BSpinner,
        BButton,
        BIconTrash,
        BFormInput,
        BFormGroup,
        BFormRadio,
        BFormSelect,
        BFormSelectOption,
        ValidationObserver,
        ValidationProvider,
        ModalConfirmVuexy,
    },
    data() {
        return {
            AMAZON_ITEM_CONDITIONS,
            Countries,
            required,
            currentMarketplaceId: null,
            itemsTableFields: [
                {
                    key: 'imageUrl',
                    label: 'Image',
                    sortable: false,
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true,
                },
                {
                    key: 'amazonSku',
                    label: 'Amazon SKU',
                    sortable: true,
                },
                {
                    key: 'asin',
                    label: 'ASIN',
                    sortable: true,
                },
                {
                    key: 'quantity',
                    label: 'Quantity to add to shipment',
                    sortable: true,
                },
                {
                    key: 'amazonQuantity',
                    label: 'Quantity in Amazon',
                    sortable: true,
                },
                {
                    key: 'quantityInBox',
                    label: 'Quantity in case',
                    sortable: true,
                },
                {
                    key: 'condition',
                    label: 'Condition',
                    sortable: true,
                },
                {
                    key: 'options',
                    label: '',
                    sortable: false,
                },
            ],
            items: [],
            loading: false,
            itemToDelete: null,
            address: {
                name: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                stateOrProvinceCode: '',
                countryCode: '',
                postalCode: '',
            },
            labelPrepPreference: '',
            shipmentName: '',
        }
    },
    watch: {
        address: {
            handler(newAddress) {
                this.$store.dispatch('setAmazonShipmentFromAddress', newAddress)
            },
            deep: true,
        },
        shipmentName(newName) {
            this.$store.dispatch('setAmazonShipmentName', newName)
        },
        labelPrepPreference(newLabelPrepPreference) {
            this.$store.dispatch('setAmazonShipmentLabelPrepPreference', newLabelPrepPreference)
        },
    },
    mounted() {
        this.$store.dispatch('loadCurrentAmazonMarketplaceId')
        this.$store.dispatch('setInitialAmazonShipmentCreationStockQuantities')
        this.$store.dispatch('loadAmazonShipmentFromAddress')
        this.$store.dispatch('loadAmazonShipmentName')
        this.$store.dispatch('loadAmazonShipmentLabelPrepPreference')
        this.currentMarketplaceId = this.$store.getters.getCurrentAmazonMarketplaceId

        const address = this.$store.getters.getAmazonShipmentFromAddress
        if (address) {
            this.address = address
        }
        const name = this.$store.getters.getAmazonShipmentName
        if (name) {
            this.shipmentName = name
        }
        const labelPrepPreference = this.$store.getters.getAmazonShipmentLabelPrepPreference
        if (labelPrepPreference) {
            this.labelPrepPreference = labelPrepPreference
        }
        this.loadItems()
    },
    methods: {
        triggerRemoveItem(item) {
            this.itemToDelete = item
            this.$root.$emit('bv::show::modal', 'removeItemModal')
        },
        removeItem() {
            this.$store.dispatch('removeAmazonShipmentCreationItem', {
                marketplaceId: this.currentMarketplaceId,
                itemId: this.itemToDelete.inventoryItemId,
            })
            this.loadItems()
        },
        updateItem(item) {
            this.$store.dispatch('setAmazonShipmentCreationStockQuantityForItem', {
                marketplaceId: this.currentMarketplaceId,
                itemId: item.inventoryItemId,
                quantityInBox: parseInt(item.quantityInBox) || 1,
                condition: item.condition,
                quantity: parseInt(item.quantity),
                listingId: item.listingId,
            })
        },
        async loadItems() {
            this.loading = true
            const itemsToFetch = (this.$store.getters.getAmazonShipmentCreationStockQuantities[this.currentMarketplaceId] || {})
            Object.keys(itemsToFetch).forEach(itemId => {
                const item = itemsToFetch[itemId]
                if (parseInt(item.quantity) === 0) {
                    delete itemsToFetch[itemId]
                }
            })
            try {
                const response = await Axios({
                    url: `${process.env.VUE_APP_API_HTTP_ROOT}amazon/get-items-for-shipment`,
                    method: 'POST',
                    data: {
                        items: itemsToFetch,
                    },
                })
                this.items = response.data.items
            } finally {
                this.loading = false
            }
        },
        validateAddress() {
            return new Promise((resolve, reject) => {
                this.$refs.addressRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        validateAdditionalInfo() {
            return new Promise((resolve, reject) => {
                this.$refs.additionalInfoRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        async createShipmentPlan() {
            this.loading = true

            try {
                await this.validateAdditionalInfo()
                const address = JSON.parse(JSON.stringify(this.address))
                address.countryCode = address.countryCode.toUpperCase()
                const data = {
                    items: this.items,
                    address: address,
                    labelPrepPreference: this.labelPrepPreference,
                    marketplaceId: this.currentMarketplaceId,
                    name: this.shipmentName,
                }
                const response = await Axios.post(`${process.env.VUE_APP_API_HTTP_ROOT}shipment-plans/create-amazon-shipment-plan`, data)
                const createdPlanId = response.data.shippingPlanId
                await this.$store.dispatch('setAmazonShipmentName', '')
                this.items.forEach(item => {
                    this.$store.dispatch('removeAmazonShipmentCreationItem', {
                        marketplaceId: this.currentMarketplaceId,
                        itemId: item.inventoryItemId,
                    })
                })
                await this.$router.push({name: 'shipments.viewAmazonShipmentPlan', params: {id: createdPlanId}})
            } catch {

            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style scoped>


</style>